<template>
	<div
		class="container-md mx-auto py-60 px-85 background-white rounded shadow-lg"
		@keydown.enter="verify"
	>
		<div class="flex flex-column align-center">
			<img
				class="mb-50"
				src="@/assets/terminal.svg"
				alt=""
			/>
		</div>

		<div>
			<h1 class="mb-20">Virkja innritan við einnýtisloynitali (2FA)</h1>

			<div class="mb-30">
				<div class="color-grey-500 mb-5">Stig 1</div>

				<h2 class="mb-10">Heinta app</h2>

				<p>Heinta eina <strong>2FA</strong> trygdarapp, t.d. Google Authenticator ella Microsoft Authenticator.</p>
			</div>

			<div class="flex">
				<div>
					<div class="color-grey-500 mb-5">Stig 2</div>

					<h2 class="mb-10">Skanna QR-kotuna</h2>

					<p>Skanna QR-kotuna við appini, fyri at generera eitt einnýtisloynital.</p>
				</div>

				<img
					v-if="url"
					:src="url"
				/>
			</div>

			<div>
				<div class="color-grey-500 mb-5">Stig 3</div>

				<h2 class="mb-20">Tøppa einnýtisloynitalið inn</h2>

				<input
					type="text"
					v-model="form.code"
					placeholder="Einnýtisloynital"
					class="w-100 mb-20 border p-20 color-grey rounded shadow"
					autofocus
				/>
			</div>

			<div
				href="#"
				@click.prevent="verify"
				class="btn hover:background-blue-600"
				:class="{ disabled: this.$v.form.$invalid }"
			>
				<div class="text-center">Virkja</div>
			</div>

			<div
				href="#"
				@click.prevent="wait"
				class="btn btn-white"
			>
				<div class="text-center">Bíða</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { helpers, required } from 'vuelidate/lib/validators';

export default {
	name: 'TwoFactor',

	data() {
		return {
			loading: false,
			url: null,

			form: {
				code: '',
			},
		};
	},

	validations: {
		form: {
			code: {
				required,
				code: helpers.regex('twoFactorCode', /^[0-9]{6}$/),
			},
		},
	},

	mounted() {
		this.generate();
	},

	computed: {
		user() {
			const localStorageUser = localStorage.getItem('user');

			if (!localStorageUser) {
				return null;
			}

			return JSON.parse(localStorageUser);
		},
	},

	methods: {
		async generate() {
			this.loading = true;

			return await axios
				.post(`${process.env.VUE_APP_TERMINAL_API_URL}/auth/2fa/generate`)
				.then(async (response) => {
					this.url = response.data.url;
				})
				.catch((e) => {
					this.$router.push({ name: 'Customers' });
				})
				.finally(() => {
					this.loading = false;
				});
		},

		async verify() {
			this.loading = true;

			return await axios
				.post(`${process.env.VUE_APP_TERMINAL_API_URL}/auth/2fa/verify`, {
					code: this.form.code,
				})
				.then(async () => {
					this.$router.push({ name: 'Customers' });

					localStorage.setItem(
						'user',
						JSON.stringify({
							...this.user,
							twoFactorVerifiedAt: new Date(),
						})
					);
				})
				.catch((e) => {})
				.finally(() => {
					this.loading = false;
				});
		},

		wait() {
			this.$router.push({ name: 'Customers' });

			sessionStorage.setItem('two-factor-wait', true);
		},
	},
};
</script>
